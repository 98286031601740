<template>
  <div>
    <titleLink title="天气" :onClick="onClickBack"></titleLink>
    <div style="padding:.3rem .2rem 0 .2rem;height:100vh;overflow-y:scroll">
      <card style="margin-bottom:3rem">
        <template #content>
          <div style="width:100%;text-align:center">
            <iframe allowtransparency="true" frameborder="0" width="140" height="278" scrolling="no" src="//tianqi.2345.com/plugin/widget/index.htm?s=2&z=3&t=1&v=1&d=3&bd=0&k=000000&f=&ltf=009944&htf=cc0000&q=1&e=1&a=1&c=54511&w=140&h=278&align=center"></iframe>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import titleLink from "@/views/components/TitleLink"
import card from "@/views/components/Card";
export default {
  components: {
    titleLink, card
  },
  methods: {
    onClickBack() {
      this.$router.push("/home");
    },
  }
}
</script>

<style>

</style>